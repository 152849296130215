var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.application)?_c('v-layout',[_c('v-btn',{staticClass:"primary--text mt-1 mr-2",attrs:{"icon":"","to":("/" + _vm.projectId + "/" + _vm.namespaceId + "/n/applications"),"router":"","exact":""}},[_c('v-icon',[_vm._v("keyboard_backspace")])],1),_c('page-title',[_vm._v("Application '"+_vm._s(_vm.application.name)+"'")]),_c('div',{staticClass:"ml-4 mt-1 mb-2"},[_c('v-btn',{staticClass:"primary--text mr-3",attrs:{"text":""},on:{"click":function($event){return _vm.refresh(false)}}},[_c('v-icon',{staticClass:"mr-1"},[_vm._v("refresh")]),_vm._v(" Refresh ")],1),_c('application-delete',{attrs:{"application":_vm.application}}),_c('application-restart',{attrs:{"application":_vm.application}}),_c('v-btn',{staticClass:"primary--text",attrs:{"text":"","to":{
          name: 'applicationAllocations',
          params: {
            project: _vm.projectId,
            namespace: _vm.namespaceId,
            application: _vm.applicationId,
          },
        },"router":"","exact":""}},[_c('v-icon',{staticClass:"mr-1"},[_vm._v("apps")]),_vm._v(" Allocations ")],1)],1)],1):_vm._e(),_c('v-divider',{staticClass:"mt-2 mb-2"}),_c('v-layout',[_c('page-subtitle',[_vm._v("View and edit your application configuration here. Learn more about app specification in the official "),_c('a',{staticClass:"ml-1",attrs:{"href":"https://docs.synpse.net/synpse-core/applications/app-specification","target":"_blank","rel":"noopener"}},[_vm._v("documentation")]),_vm._v(". ")]),_c('v-spacer'),_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var tooltip = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","href":"https://docs.synpse.net/synpse-core/applications/app-specification","target":"_blank","rel":"noopener"}},tooltip),[_c('v-icon',{attrs:{"color":"grey"}},[_vm._v("help")])],1)]}}])},[_c('span',[_vm._v(" Find out more about the application spec format here: https://docs.synpse.net/synpse-core/applications/app-specification ")])])],1),_c('v-layout',{staticClass:"mb-3",attrs:{"wrap":""}},[_c('v-flex',{attrs:{"lg8":"","md12":"","sm12":"","pa-2":""}},[_c('v-card',{attrs:{"flat":"","outlined":""}},[_c('v-card-text',[_c('codemirror',{attrs:{"options":_vm.codeMirrorOptions},model:{value:(_vm.applicationYaml),callback:function ($$v) {_vm.applicationYaml=$$v},expression:"applicationYaml"}})],1),_c('v-card-actions',[(_vm.error)?_c('v-alert',{attrs:{"dense":"","outlined":"","type":"error"}},[_vm._v(" "+_vm._s(_vm.error)+" ")]):_vm._e(),(_vm.yamlError)?_c('v-alert',{attrs:{"dense":"","outlined":"","type":"error"}},[_vm._v(" "+_vm._s(_vm.yamlError)+" ")]):_vm._e(),_c('v-spacer'),_c('v-btn',{staticClass:"primary--text",attrs:{"text":"","disabled":_vm.loading,"loading":_vm.loading},on:{"click":function($event){return _vm.updateApplicationSpec()}}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v("save")]),_vm._v(" Save ")],1)],1)],1)],1),_c('v-flex',{attrs:{"lg4":"","md12":"","sm12":"","pa-2":""}},[(_vm.application)?_c('v-card',{attrs:{"flat":"","outlined":""}},[_c('v-card-title',[_vm._v(" Status "),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var tooltip = ref.on;
return [_c('v-icon',_vm._g({staticClass:"ml-2",attrs:{"small":"","color":"grey"}},tooltip),[_vm._v("help")])]}}],null,false,46298049)},[_c('span',[_vm._v(" Deployment status tracks application rollout across your fleet. ")])])],1),(_vm.hints.length > 0)?_c('v-card-text',[_c('application-hints',{attrs:{"hints":_vm.hints}})],1):_vm._e(),_c('v-card-text',[_c('v-list-item',[_c('v-list-item-avatar',[(_vm.application.deploymentStatus.pending > 0)?_c('v-progress-circular',{attrs:{"indeterminate":"","color":"primary"}}):_c('v-progress-circular',{attrs:{"value":100,"color":"secondary"}})],1),_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(("Pending: " + (_vm.application.deploymentStatus.pending)))}}),_c('v-list-item-subtitle')],1)],1),_c('v-list-item',[_c('v-list-item-avatar',[_c('v-progress-circular',{attrs:{"value":(_vm.application.deploymentStatus.available/_vm.application.deploymentStatus.total)*100,"color":_vm.application.deploymentStatus.available > 0 ? 'green accent-5' : ''}})],1),_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(("Running: " + (_vm.application.deploymentStatus.available) + "/" + (_vm.application.deploymentStatus.total)))}}),_c('v-list-item-subtitle')],1)],1)],1)],1):_vm._e(),_c('br'),_c('v-card',{attrs:{"flat":"","outlined":""}},[_c('v-card-title',[_vm._v(" Namespace Secrets "),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var tooltip = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","href":"https://docs.synpse.net/applications/secrets","target":"_blank","rel":"noopener"}},tooltip),[_c('v-icon',{attrs:{"color":"grey","small":""}},[_vm._v("help")])],1)]}}])},[_c('span',[_vm._v(" Secrets can be stored separately from your application spec, improving security. ")])]),_c('v-spacer'),_c('secret-create')],1),_c('v-card-text',[(_vm.secrets.length == 0)?_c('p',[_vm._v(" Secrets enable your applications to receive sensitive data (for example cloud credentials, admin passwords, etc.) without adding them to the app spec directly. ")]):_c('v-list',{attrs:{"subheader":"","two-line":"","dense":""}},_vm._l((_vm.secrets),function(item,key){return _c('v-list-item',{key:key},[_c('v-list-item-avatar',[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.copySecretToClipboard(item)}}},[_c('v-icon',{attrs:{"color":""}},[_vm._v("mdi-content-copy")])],1)],1),_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(("" + (item.name)))}}),_c('v-list-item-subtitle',[_vm._v(" Created "+_vm._s(_vm._f("ago")(item.createdAt))+" ")])],1)],1)}),1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <v-dialog v-if="application" v-model="dialog" max-width="500px">
    <template v-slot:activator="{ on }">
      <v-btn text class="primary--text" v-on="on">
        <v-icon class="mr-1">delete</v-icon>
        Delete
      </v-btn>
    </template>
    <v-card>
      <v-card-title>
        <span class="headline">Delete Application</span>
      </v-card-title>
      <v-card-text>
        Are you sure want to delete application
        <strong>{{ application.name }}</strong
        >?
        <br/>
        <p>
          It might be a good idea to back it up in either
          <a href="https://gist.github.com/" target="_blank" rel="noopener"
            >GithubGist</a
          >
          or a any other source control.
        </p>
      </v-card-text>
      <v-card-actions>
        <v-btn class="primary" text @click.native="dialog = false"
          >Cancel</v-btn
        >
        <v-spacer></v-spacer>
        <v-btn class="primary--text" text @click.native="deleteApplication()"
          >Delete</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      dialog: false
    }
  },

  props: {
    application: Object
  },

  computed: {
    error() {
      return this.$store.state.application.error
    },
    projectId() {
      return this.$route.params.project
    },
    selectedNamespaceId() {
      return this.$route.params.namespace
    }
  },

  methods: {
    deleteApplication() {
      this.$store.dispatch('DeleteApplication', this.application).then(() => {
        if (this.error == null) {
          this.dialog = false
          this.$router.push({
            name: 'applications', params: {
              project: this.projectId, namespace: this.selectedNamespaceId
            }
          })
        }
      })
    }
  }

}
</script>

<template>
  <div>
    <v-layout v-if="application">
      <v-btn
        icon
        class="primary--text mt-1 mr-2"
        :to="`/${projectId}/${namespaceId}/n/applications`"
        router
        exact
      >
        <v-icon>keyboard_backspace</v-icon>
      </v-btn>

      <page-title>Application '{{ application.name }}'</page-title>

      <div class="ml-4 mt-1 mb-2">
        <!-- TODO: button to add a device -->
        <v-btn class="primary--text mr-3" text @click="refresh(false)">
          <v-icon class="mr-1">refresh</v-icon>
          Refresh
        </v-btn>

        <application-delete :application="application"></application-delete>
        <application-restart :application="application"></application-restart>

        <v-btn
          text
          class="primary--text"
          :to="{
            name: 'applicationAllocations',
            params: {
              project: projectId,
              namespace: namespaceId,
              application: applicationId,
            },
          }"
          router
          exact
        >
          <v-icon class="mr-1">apps</v-icon>
          Allocations
        </v-btn>
      </div>
    </v-layout>

    <v-divider class="mt-2 mb-2"></v-divider>

    <v-layout>
      <page-subtitle
        >View and edit your application configuration here. Learn more about app
        specification in the official
        <a
          href="https://docs.synpse.net/synpse-core/applications/app-specification"
          class="ml-1"
          target="_blank"
          rel="noopener"
          >documentation</a
        >.
      </page-subtitle>
      <v-spacer></v-spacer>
      <v-tooltip left>
        <template v-slot:activator="{ on: tooltip }">
          <v-btn
            icon
            v-on="tooltip"
            href="https://docs.synpse.net/synpse-core/applications/app-specification"
            target="_blank"
            rel="noopener"
          >
            <v-icon color="grey">help</v-icon>
          </v-btn>
        </template>
        <span>
          Find out more about the application spec format here:
          https://docs.synpse.net/synpse-core/applications/app-specification
        </span>
      </v-tooltip>
    </v-layout>

    <v-layout class="mb-3" wrap>
      <v-flex lg8 md12 sm12 pa-2>
        <v-card flat outlined>
          <v-card-text>
            <codemirror
              v-model="applicationYaml"
              :options="codeMirrorOptions"
            ></codemirror>
          </v-card-text>
          <v-card-actions>
            <v-alert v-if="error" dense outlined type="error">
              {{ error }}
            </v-alert>
            <v-alert v-if="yamlError" dense outlined type="error">
              {{ yamlError }}
            </v-alert>
            <v-spacer></v-spacer>

            <v-btn
              text
              class="primary--text"
              :disabled="loading"
              :loading="loading"
              @click="updateApplicationSpec()"
            >
              <v-icon class="mr-2">save</v-icon>
              Save
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-flex>
      <v-flex lg4 md12 sm12 pa-2>
        <v-card v-if="application" flat outlined>
          <v-card-title>
            Status
            <v-tooltip top>
              <template v-slot:activator="{ on: tooltip }">
                <v-icon v-on="tooltip" small color="grey" class="ml-2"
                  >help</v-icon
                >
              </template>
              <span>
                Deployment status tracks application rollout across your fleet.
              </span>
            </v-tooltip>
          </v-card-title>
          <v-card-text v-if="hints.length > 0">
            <application-hints :hints="hints"> </application-hints>
          </v-card-text>
          <v-card-text>
            <!-- <v-list subheader> -->
              <!-- Pending -->
              <v-list-item>
                <v-list-item-avatar>
                  <v-progress-circular
                    v-if="application.deploymentStatus.pending > 0"
                    indeterminate
                    color="primary"
                  ></v-progress-circular>
                  <v-progress-circular
                    v-else
                    :value="100"
                    color="secondary"
                  ></v-progress-circular>
                </v-list-item-avatar>

                <v-list-item-content>
                  <v-list-item-title
                    v-text="`Pending: ${application.deploymentStatus.pending}`"
                  ></v-list-item-title>
                  <v-list-item-subtitle> </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <!-- Running -->
              <v-list-item>
                <v-list-item-avatar>
                  <v-progress-circular
                    :value="(application.deploymentStatus.available/application.deploymentStatus.total)*100"
                    :color="application.deploymentStatus.available > 0 ? 'green accent-5' : ''"
                  ></v-progress-circular>

                </v-list-item-avatar>

                <v-list-item-content>
                  <v-list-item-title
                    v-text="`Running: ${application.deploymentStatus.available}/${application.deploymentStatus.total}`"
                  ></v-list-item-title>
                  <v-list-item-subtitle> </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            <!-- </v-list> -->
          </v-card-text>
        </v-card>
        <br/>

        <v-card flat outlined>
          <v-card-title>
            Namespace Secrets
            <v-tooltip top>
              <template v-slot:activator="{ on: tooltip }">
                <v-btn
                  icon
                  v-on="tooltip"
                  href="https://docs.synpse.net/applications/secrets"
                  target="_blank"
                  rel="noopener"
                >
                  <v-icon color="grey" small>help</v-icon>
                </v-btn>
              </template>
              <span>
                Secrets can be stored separately from your application spec,
                improving security.
              </span>
            </v-tooltip>
            <v-spacer></v-spacer>
            <secret-create></secret-create>
          </v-card-title>
          <v-card-text>
            <p v-if="secrets.length == 0">
              Secrets enable your applications to receive sensitive data (for
              example cloud credentials, admin passwords, etc.) without adding
              them to the app spec directly.
            </p>
            <v-list v-else subheader two-line dense>
              <v-list-item v-for="(item, key) in secrets" :key="key">
                <v-list-item-avatar>
                  <v-btn icon @click="copySecretToClipboard(item)">
                    <v-icon color="">mdi-content-copy</v-icon>
                  </v-btn>
                </v-list-item-avatar>

                <v-list-item-content>
                  <v-list-item-title
                    v-text="`${item.name}`"
                  ></v-list-item-title>
                  <v-list-item-subtitle>
                    Created {{ item.createdAt | ago }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>
  </div>
</template>

<style >
.CodeMirror {
  border: 1px solid #eee;
  height: auto;
}
</style>

<script>
import PageSubtitle from '../PageSubtitle';
import PageTitle from '../PageTitle';
import ApplicationDelete from './ApplicationDelete';
import ApplicationRestart from './ApplicationRestart';
import SecretCreate from '../secrets/SecretCreate';
import ApplicationHints from './ApplicationHints';

import YAML from 'yaml';

// Editor
import { codemirror } from 'vue-codemirror';

// require styles
import 'codemirror/lib/codemirror.css';

// language js
import 'codemirror/mode/yaml/yaml.js'

// theme css
import 'codemirror/theme/base16-dark.css';

export default {
  components: {
    PageSubtitle,
    PageTitle,
    ApplicationDelete,
    ApplicationRestart,
    SecretCreate,
    ApplicationHints,
    codemirror
  },
  data() {
    return {
      applicationYaml: '',
      yamlError: null
    }
  },

  computed: {
    projectId() {
      return this.$route.params.project
    },
    namespaceId() {
      return this.$route.params.namespace
    },
    applicationId() {
      return this.$route.params.application
    },
    application() {
      return this.$store.state.application.application
    },
    secrets() {
      return this.$store.state.secret.secrets
    },
    hints() {
      return this.$store.state.application.applicationHints
    },
    // Selected secret
    secret() {
      return this.$store.state.secret.secret
    },
    loading() {
      return this.$store.state.application.loading
    },
    error() {
      return this.$store.state.application.error
    },
    theme() {
      return 'vs-dark'
    },
    codeMirrorOptions() {
      let saveApplication = this.updateApplicationSpec

      let options = {
        // codemirror options
        tabSize: 4,
        mode: 'text/x-yaml',
        theme: 'base16-dark',
        lineNumbers: true,
        line: true,
        extraKeys: {
          "Ctrl-S": function() { 
            saveApplication()
            return false;
          }
        }
      }
      return options 
    },
  },

  mounted() {
    this.refresh(false)
    this.subscribe()
  },

  beforeDestroy() {
    this.$store.dispatch('UnsubscribeFromSSE')
  },

  beforeUnmount() {
    this.$store.dispatch('UnsubscribeFromSSE')
  },

  methods: {
    refresh(background = true) {
      let projectQuery = {
        projectId: this.projectId
      }
      this.$store.dispatch('GetProject', projectQuery)

      let q = {
        projectId: this.projectId,
        namespaceId: this.namespaceId,
        applicationId: this.applicationId,
        background: background
      }
      this.$store.dispatch('GetApplicationHints', q)

      this.$store.dispatch('GetApplication', q).then(() => {
        if (background) {
          // If it's a background refresh, don't update the yaml
          // as it might be edited already
          return
        }
        // stripping down a bit
        const basicManifest = {
          name: this.application.name,
          description: this.application.description,
          type: this.application.container,
          scheduling: this.application.scheduling,
          spec: this.application.spec
        }
        this.applicationYaml = YAML.stringify(basicManifest)
      })
      // Loading secrets
      this.$store.dispatch('ListSecrets', q)
    },
    subscribe() {
      let token = this.$store.getters.jwt
      let streamUrl = `/api/projects/${this.projectId}/events?_t=${token}&stream=${this.projectId}:applicationStatuses:${this.applicationId}`

      let q = {
        stream: streamUrl,
        callback: this.refresh
      }

      this.$store.dispatch('SubscribeToSSE', q)
    },
    copySecretToClipboard(secret) {
      this.$store.dispatch('Notify', `Copied!`)
      this.$clipboard(secret.name)
    },
    updateApplicationSpec: function () {
      this.yamlError = null
      let parsed = ''
      let application = ''
      try {
        parsed = YAML.parse(this.applicationYaml)

        // Making a copy of the application so vuex doesn't complain
        application = JSON.parse(JSON.stringify(this.application))
      }
      catch (err) {
        this.yamlError = err
        return
      }

      application.name = parsed.name
      application.description = parsed.description
      application.type = parsed.type
      application.scheduling = parsed.scheduling
      application.spec = parsed.spec

      this.$store.dispatch('UpdateApplication', application).then(() => {
        if (this.error === null) {
          // All good, refreshing
          this.refresh()
          this.$store.dispatch('Notify', `Application updated`)
          return
        }
        // Showing error
      })
    }
  }
}
</script>

<template>  
  <v-dialog v-model="dialog" width="700">
    <template v-slot:activator="{ on, attrs }">
      <v-btn 
         v-if="warningFound"
         color="warning"
         outlined small dark v-bind="attrs" v-on="on"
        
      >
        <v-icon small class="mr-1">warning_amber</v-icon>
        Potential Issues Found
      </v-btn>
      <!-- TODO: look for errors -->
      <v-btn
        v-else 
        small
        color="info" dark v-bind="attrs" v-on="on">
        <v-icon small class="mr-1">info</v-icon>
        View Hints
      </v-btn>
    </template>

    <v-card>
      <v-card-title class="headline">
        Potential issues found
      </v-card-title>
      <v-card-text>
        <v-list subheader two-line dense>
          <v-list-item v-for="(hint, key) in hints" :key="key">
            <v-list-item-avatar>              
              <v-icon v-if="hint.severity === 'warning'" color="warning">warning_amber</v-icon>             
              <v-icon v-if="hint.severity === 'info'" color="info">info</v-icon>             
            </v-list-item-avatar>

            <v-list-item-content class="text-caption">
              {{ hint.message }}

            <v-list-item-subtitle v-if="hint.type === 'noDockerRegistryCredentials'">
              Read more in the <a href="https://docs.synpse.net/synpse-core/applications/registry-authentication" 
                target="_blank" rel="noopener">registry authentication docs</a>. DockerHub
              <a href="https://docs.docker.com/docker-hub/download-rate-limit/" target="_blank" rel="noopener">rate limits docs</a>.
            </v-list-item-subtitle>
            <v-list-item-subtitle v-if="hint.type === 'noDevices'">
              Read more in the <a href="https://docs.synpse.net/agent/install" 
                target="_blank" rel="noopener">installation docs</a>.
            </v-list-item-subtitle>
            <!-- TODO: more links based on types -->

            </v-list-item-content>            
          </v-list-item>
        </v-list>
      </v-card-text>

      <v-divider></v-divider>
      
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="dialog = false">Okay, thanks</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    hints: Array
  },

  data () {
    return {
      dialog: false
    }
  },

  computed: {
    warningFound() {      
      console.log(this.hints.some(hint => hint.severity === 'warning'))
      return this.hints.some(hint => hint.severity === 'warning')
    },    
  }
}
</script>